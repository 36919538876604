import ApiService from './api.service'
import {
  WidgetModel,
  FormBuilderDTO,
  WidgetMetaModel,
  WidgetCreateModel,
  LandingPageDto,
  LandingType,
} from "../definitions/Landing";
import { WidgetPage } from "../constants/enums";


const LandingService = {
  getWidgets: async (searchString: string, skip: number, take: number): Promise<WidgetMetaModel[]> => {
    const url = `/api/admin/landing?searchString=${searchString}&skip=${skip}&take=${take}`;
    const result = await ApiService.get<WidgetMetaModel[]>(url);
    return result;
  },
  getWidgetsByPage: async (page: WidgetPage, skip: number, take: number, subPage = "", unitCommune: number | null): Promise<WidgetModel> => {
    let url = `/api/Landing/widgets/${page}?skip=${skip}&take=${take}&subPage=${subPage}`;
    if(unitCommune) url += `&unitCommune=${unitCommune}`;
    
    const result = await ApiService.get<WidgetModel>(url);
    return result;
  },
  getEditor: async (id: string): Promise<FormBuilderDTO> => {
    const url = `/api/admin/Landing/${id}/editor`
    const result = await ApiService.get<FormBuilderDTO>(url);
    return result;
  },
  getMessageEditor: async (id: string, type: LandingType): Promise<FormBuilderDTO> => {
    const url = `/api/admin/litecampaign/message/${id}/editor/${type}`
    const result = await ApiService.get<FormBuilderDTO>(url);
    return result;
  },
  getWidget: async (id: string): Promise<WidgetMetaModel> => {
    const url = `/api/admin/landing/${id}`;
    const result = await ApiService.get<WidgetMetaModel>(url);
    return result;
  },
  addWidget: async (widget: WidgetCreateModel): Promise<WidgetModel> => {
    const url = `/api/admin/landing/widgets`
    const result = await ApiService.post<WidgetModel>(url, widget);
    return result;
  },
  updateWidget: async (widget: WidgetMetaModel): Promise<WidgetMetaModel> => {
    const url = `/api/admin/landing/${widget.Id}`;
    const result = await ApiService.put<WidgetMetaModel>(url, widget);
    return result;
  },
  deleteWidget: async (widgetId: string) => {
    const url = `/api/admin/landing/${widgetId}`;
    const result = ApiService.delete(url);
    return result;
  },
  getCustomersCount: () => {
    const url = '/api/Landing/users-count'
    const result = ApiService.get(url);
    return result
  },
 
  getLandingPage: async (uniqueName: string, customerId: string, debug: boolean, taskId: string, userId: string, tmCodeId: string): Promise<LandingPageDto> => {
    if(debug){
      const url = `/api/landing/landingdebug/${customerId}/${uniqueName}`;
      const result = await ApiService.get<LandingPageDto>(url);
      return result;
    } else {
        if (userId == "") {
            userId = "_";
        }
        let url = `/api/landing/${customerId}/${uniqueName}/${userId}`;
        if (taskId != "") {
            url += `/${taskId}`;
        }
      const result = await ApiService.get<LandingPageDto>(url);
      return result;
    }
    
  },
 
  
}

export default LandingService;

export const signUpClubBenefit = async (email: string): Promise<void> => {
  const url = `/api/landing/club-signup`
  const result = await ApiService.post<void>(url, { Email: email })
  return result
}