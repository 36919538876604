import { AppThunkDispatch } from "../definitions/Action";
import store from "../store/store";
import CampaignService from "../services/campaign.service";
import {CampaignActionTypes} from "../constants/campaing.actiontypes";
export const unsubscribe = () => async (
    dispatch: AppThunkDispatch
  ): Promise<boolean | null> => {
  
    const { userId} = store.getState().user;
    const result = await CampaignService.unsubscribe(userId);
    dispatch({ type: CampaignActionTypes.UNSUBSCRIBE, payload: true })
    return result;
  };