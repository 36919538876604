import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { isReactComponent } from ".";
import {
  GrapesEditor,
  GrapesTypes,
  LoadComponentOptions,
} from "../../defenitions";
import UserNameComponent from "../../../Base/UserName";
import { Provider } from "react-redux";
import store from "../../../../store/store";

type UserNameProps = {
  text: string;
  prefix: string;
  suffix: string;
};

export const UserName = (props: UserNameProps): JSX.Element => {
 

  return (
    <Provider store={store}>
    <UserNameComponent  />
  </Provider>
  );
};

export default function (
  editor: GrapesEditor,
  options: LoadComponentOptions
): void {
  const domComponents = editor.DomComponents;
  const blockManager = editor.BlockManager;
  const defaultType = domComponents.getType("default");
  const { model, view } = defaultType;
  const compName = "UserName";

  blockManager.add(GrapesTypes.UserName, {
    label: "User name",
    category: options.categoryLabel,
    attributes: { class: "fa fa-photo" },
    textable: 1,
    content: `<span data-gjs-type="${GrapesTypes.UserName}"></span>`,
  });



  

  const traits = [
    /* {
      label: "Prefix text",
      name: "prefix",
      changeProp: 1,
    },
    {
      label: "Suffix text",
      name: "suffix",
      changeProp: 1,
    }, */
   

  ];
  let originalText = domComponents.getType('text');

  domComponents.addType(GrapesTypes.UserName, {
    isComponent(el: HTMLElement) {
      if (
        (el.getAttribute &&
          el.getAttribute("data-gjs-type") ===
            GrapesTypes.UserName) ||
        (el.attributes &&
          (el.attributes as any)["data-gjs-type"] ===
            GrapesTypes.UserName) ||
        isReactComponent(el, compName)
      ) {
        return {
          type: GrapesTypes.UserName,
        };
      }
    },
    model: originalText.model.extend( {
      defaults: {
        ...model.prototype.defaults,
        droppable: false,
        draggable: true,
        text: "Link",
        traits: [...model.prototype.defaults.traits, ...traits],
      },
    }),
    view: originalText.view.extend({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      handleChanges(e: any, value: any) {
        ReactDOM.unmountComponentAtNode(this.el);
        this.render();
      },
      onRender() {
        const { el } = this;
        const comps = this.model.get("components");
        const { text, prefix, suffix } = this.model.attributes;
        comps.reset();
        const compString = `<${compName} ></${compName}>`;
        comps.add(compString);
      

        ReactDOM.render(<Provider store={store}>
          <UserNameComponent />
        </Provider>, el);
      },
    }),
  });
}
