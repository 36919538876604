 import ShowLinkDialog from "./ShowLinkDialog";

type ToolBarButton = {
  command: string;
  label: string;
  toolbar: string;
  icon: string;
  buttonName: string;
  dialogName?: string;
  externalId?: string;
  buttonClick?: (e: any) => void;
  callDialogMethod?: (dialog: any, externalId?: string) => void;
};

export default function (CKEDITOR: any, externalId?: string) {
  const controls: Array<ToolBarButton> = [
    {
      command: "shortLink",
      label: "Insert Short Link",
      icon: "Link",
      toolbar: "template",
      buttonName: "shortLinkButton",
      dialogName: "shortLinkDialog",
      externalId: externalId,
      callDialogMethod: ShowLinkDialog
    },
    {
      command: "unsubcribeLink",
      label: "Insert Unsubscribe Link",
      icon: "Anchor",
      toolbar: "template",
      buttonName: "unsubscibeLinkButton",
      buttonClick: (e: any) => {
        e.insertHtml(` <a href="${window.location.origin}/unsubscribe?email=<%[?(@.TypeName === 'UserModel')].Email%>">Unsubscribe</a> `);
      },
    },
  ];

  //add "template" plugin once
  if (!CKEDITOR.plugins.get("template")) {
    CKEDITOR.plugins.add("template", {
      init: function (editor: any) {
        controls.forEach((c) => {
          if (c.dialogName)
            editor.addCommand(
              c.command,
              new CKEDITOR.dialogCommand(c.dialogName)
            );
          
          editor.ui.addButton(c.buttonName, {
            label: c.label,
            command: c.command,
            toolbar: c.toolbar,
            icon: c.icon,
          });

          if(c.buttonClick) editor.addCommand(c.command, { exec: c.buttonClick})
        });

        editor.on("doubleclick", function (evt: any) {
          const element =
            CKEDITOR.plugins.link.getSelectedLink(editor) || evt.data.element;

          controls.forEach((c) => {
            if (element.is(c.command)) {
              editor.execCommand(c.command);
            }
          });
        });
      },
    });

    controls.forEach((c) => {
      if(c.callDialogMethod) c.callDialogMethod(CKEDITOR.dialog, c.externalId);
    });
  }
}
