import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { isReactComponent } from ".";
import {
  GrapesEditor,
  GrapesTypes,
  LoadComponentOptions,
} from "../../defenitions";
import CampaignUnsubscribeComponent from "../../../Base/CampaignUnsubscribe";
import { Provider } from "react-redux";
import store from "../../../../store/store";


type UnsubscribeProps = {
  buttonText: string;
  message: string
};

export const CampaignUnsubscribe = (props: UnsubscribeProps): JSX.Element => {
  const { buttonText, message } = props;
 
  return (
    <Provider store={store}>
          <CampaignUnsubscribeComponent buttonText={buttonText} message={message} />
        </Provider>
  );
};

export default function (
  editor: GrapesEditor,
  options: LoadComponentOptions
): void {
  const domComponents = editor.DomComponents;
  const blockManager = editor.BlockManager;
  const defaultType = domComponents.getType("default");
  const { model, view } = defaultType;
  const compName = "CampaignUnsubscribe";

  blockManager.add(GrapesTypes.CampaignUnsubscribe, {
    label: "Campaign usubscribe button",
    category: options.categoryLabel,
    attributes: { class: "fa fa-sign-out" },
    textable: 1,
    content: `<span data-gjs-type="${GrapesTypes.CampaignUnsubscribe}"></span>`,
  });



  

  const traits = [
    {
      label: "Button text",
      name: "buttonText",
      changeProp: 1,
    },
    {
      label: "Message text",
      name: "message",
      changeProp: 1,
    },

  ];


  domComponents.addType(GrapesTypes.CampaignUnsubscribe, {
    isComponent(el: HTMLElement) {
      if (
        (el.getAttribute &&
          el.getAttribute("data-gjs-type") ===
            GrapesTypes.CampaignUnsubscribe) ||
        (el.attributes &&
          (el.attributes as any)["data-gjs-type"] ===
            GrapesTypes.CampaignUnsubscribe) ||
        isReactComponent(el, compName)
      ) {
        return {
          type: GrapesTypes.CampaignUnsubscribe,
        };
      }
    },
    model: {
      defaults: {
        ...model.prototype.defaults,
        droppable: false,
        draggable: true,
        buttonText: "Unsubscribe",
        message: "Thank you!",
        traits: [...model.prototype.defaults.traits, ...traits],
      },
    },
    view: view.extend({
      init() {
        traits.forEach((trait) => {
          this.listenTo(this.model, `change:${trait.name}`, this.handleChanges);
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      handleChanges(e: any, value: any) {
        ReactDOM.unmountComponentAtNode(this.el);
        this.render();
      },
      onRender() {
        const { el } = this;
        const comps = this.model.get("components");
        const { buttonText, message } = this.model.attributes;
        comps.reset();

        const compString = `<${compName} buttonText='${buttonText}' message='${message}'></${compName}>`;
        comps.add(compString);

        ReactDOM.render(<Provider store={store}>
          <CampaignUnsubscribe buttonText={buttonText} message={message}/>
        </Provider>, el);
      },
    }),
  });
}
