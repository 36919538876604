import React, { useEffect, useReducer } from "react";
import {
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
} from "@mui/material";

//import { AppThunkDispatch } from "../../../../definitions/Action";
import { connect, ConnectedProps } from "react-redux";
import {
  setUrl,
  getLanding,
  updateLanding,
  updateFacebookPicture,
  getEditor
} from "../../actions/landingAdmin";


import LandingForm from "./components/LandingForm";
import { ExpandMore } from "@mui/icons-material";
import GrapesJsEditor from "../../components/Grapes/GrapesJsEditor";
import {
  FormBuilderDTO,
  LandingMetaModel,
  LandingType,
} from "../../definitions/Landing"
import { AppThunkDispatch } from "../../definitions/Action";
import { useParams } from "react-router";



type EditLandingPageState = {
  accordionOpen: boolean;
  landing: LandingMetaModel | null;
  editor: FormBuilderDTO | null;
  landingLoading: boolean;
  editorLoading: boolean;
};

const initialState: EditLandingPageState = {
  accordionOpen: false,
  landing: null,
  editor: null,
  landingLoading: false,
  editorLoading: false,
};

const reducer = (
  state: EditLandingPageState,
  action: { type: string; payload?: unknown }
) => {
  switch (action.type) {
    case "toggleAccordion":
      return { ...state, accordionOpen: !state.accordionOpen };
    default:
      return {
        ...state,
        [action.type]: action.payload,
      };
  }
};

const EditLandingPage = (props: EditLandingConnectedProps) => {
  const { id } = useParams<{id: string}>();

  const [state, dispatch] = useReducer(reducer, initialState);
  const onFullfilled = (type: string) => (result: unknown) =>
    dispatch({ type, payload: result });
  const toggleAccordion = () => dispatch({ type: "toggleAccordion" });
  const setIsLoading = (name: string, value: boolean) =>
    dispatch({ type: name + "Loading", payload: value });



  useEffect(() => {
 

    if (!state.landing) {
      setIsLoading("landing", true);
      props.getLanding(id ?? "")
        .then(onFullfilled("landing"))
        .finally(() => setIsLoading("landing", false));
    }

    if (state.landing) {
      setIsLoading("editor", true);
      props.getEditor(state.landing.ExternalLandingId)
        .then(onFullfilled("editor"))
        .finally(() => setIsLoading("editor", false));
    }
  }, [state.landing, getLanding, getEditor]);

  const handleUpdateLanding = async (landing: LandingMetaModel) => {
    const result = await updateLanding(landing);
    dispatch({ type: "landing", payload: landing });
    return result;
  };
  let updateLanding = props.updateLanding;
  let updateFacebookPicture = props.updateFacebookPicture;
  return (
    <Grid container justifyContent="center" spacing={1}>

      <Grid item xs={12}>
        {!state.editorLoading && state.editor && (
          <GrapesJsEditor
            id="landingEditor"
            type={LandingType.Landing}
            builder={state.editor}
          />
        )}
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  getEditor: (editorId: string) => dispatch(getEditor(editorId)),
  getLanding: (landingId: string) => dispatch(getLanding(landingId)),
  updateLanding: (landing: LandingMetaModel) =>
    dispatch(updateLanding(landing)),
  updateFacebookPicture: (landingId: string, picture: File) =>
    dispatch(updateFacebookPicture(landingId, picture)),
  setUrl: (url: string) => dispatch(setUrl(url))
});

const connector = connect(null, mapDispatchToProps);

type EditLandingConnectedProps = ConnectedProps<typeof connector>;

export default connector(EditLandingPage);
