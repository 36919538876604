import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { unsubscribe } from '../../actions/userActions';


function UnsubscribeComponent({ buttonText, message, unsubscribed,redirectUrl, unsubscribe }) {
  const handleButtonClick = () => {
    // Validate email format
  
    unsubscribe();
    
  };
  useEffect(() => {
    // Check if consent changes to true
    if (unsubscribed) {
      window.location.href = redirectUrl
    }
  }, [unsubscribed]);
  return (

<div>
      {!unsubscribed ? (
        <button onClick={handleButtonClick}>{buttonText}</button>
      ) : (
        <p>{message}</p>
      )}
    </div>
    
  );
}

const mapStateToProps = (state) => {
  return {
    unsubscribed: state.user.unsubscribed,
    redirectUrl: state.landing.landing.RedirectUrl
  };
};

export default connect(mapStateToProps, { unsubscribe })(UnsubscribeComponent);