export const AdminLandingActionTypes = {
    LANDING_OPERATION_FAILED: 'LANDING_OPERATION_FAILED',
    GET_WIDGETS: "ADMIN_GET_WIDGETS",
    GET_WIDGETS_SUCCEEDED: "ADMIN_GET_WIDGETS_SUCCEEDED",
    GET_WIDGET: "GET_WIDGET",
    GET_WIDGET_SUCCEEDED: "GET_WIDGET_SUCCEEDED", 
    ADD_WIDGET: "ADD_WIDGET",
    ADD_WIDGET_SUCCEEDED: "ADD_WIDGET_SUCCEEDED",
    GET_EDITOR: "GET_EDITOR",
    GET_EDITOR_SUCCEEDED: "GET_EDITOR_SUCCEEDED",
    GET_MESSAGE_EDITOR: "GET_MESSAGE_EDITOR",
    GET_MESSAGE_EDITOR_SUCCEEDED: "GET_MESSAGE_EDITOR_SUCCEEDED",
    GET_LANDINGS: "GET_LANDINGS",
    GET_LANDINGS_SUCCEEDED: "GET_LANDINGS_SUCCEEDED",
    GET_LANDING: "GET_LANDING",
    GET_LANDING_SUCCEEDED: "GET_LANDING_SUCCEEDED",
    ADD_LANDING: "ADD_LANDING",
    ADD_LANDING_SUCCEEDED:"ADD_LANDING_SUCCEEDED",
    ADD_TEMPLATE:"ADD_TEMPLATE",
    UPDATE_LANDING:"UPDATE_LANDING",
    UPDATE_LANDING_SUCCEEDED: "UPDATE_LANDING_SUCCEEDED",
    CLONE_LANDING: "CLONE_LANDING",
    CLONE_LANDING_SUCCEEDED: "CLONE_LANDING_SUCCEEDED",
    UPDATE_FACEBOOK_PICTURE: "UPDATE_LANDING_FACEBOOK_PICTURE",
    UPDATE_FACEBOOK_PICTURE_SUCCEEDED: "UPDATE_LANDING_FACEBOOK_PICTURE_SUCCEEDED",
    ADD_TEMPLATE_SUCCEEDED:"ADD_TEMPLATE_SUCCEEDED",
    GET_TEMPLATE: "GET_TEMPLATE",
    GET_TEMPLATE_SUCCEEDED: "GET_TEMPLATE_SUCCEEDED",
    GET_TEMPLATES: "GET_TEMPLATES",
    GET_TEMPLATES_SUCCEEDED: "GET_TEMPLATES_SUCCEEDED",
    UPDATE_TEMPLATE:"UPDATE_TEMPLATE",
    UPDATE_TEMPLATE_SUCCEEDED: "UPDATE_TEMPLATE_SUCCEEDED",
    REMOVE_TEMPLATE: "REMOVE_TEMPLATE",
    REMOVE_TEMPLATE_SUCCEEDED: "REMOVE_TEMPLATE_SUCCEEDED",
    CLEAR_TEMPLATES: "CLEAR_TEMPLATES",
    REMOVE_LANDING:"REMOVE_LANDING",
    REMOVE_LANDING_SUCCEEDED:"REMOVE_LANDING",
    SET_CURRENT_WIDGET:"SET_CURRENT_WIDGET",
    UPDATE_WIDGET:"UPDATE_WIDGET",
    UPDATE_WIDGET_SUCCEEDED:"UPDATE_WIDGET_SUCCEEDED",
    REMOVE_WIDGET: "REMOVE_WIDGET",
    REMOVE_WIDGET_SUCCEEDED: "REMOVE_WIDGET_SUCCEEDED", 
    RESET_ERROR:"RESET_ERROR",
    RESET_WIDGETS: "RESET_WIDGETS",
    SET_SEARCH_STRING: "SET_SEARCH_STRING",
    ADD_LEAD: "ADMIN_ADD_LEAD",
    ADD_LEAD_SUCCEEDED: "ADMIN_ADD_LEAD_SUCCEEDED",
    CLEAR_LANDINGS:"CLEAR_LANDINGS",
    SET_API_URL: "SET_API_URL"
}