import { UserActionTypes } from "../definitions/userActions";
import { AppThunkDispatch } from "../definitions/Action";
import store from "../store/store";
import UserConsentService from "../services/user-consent.service";
import { CreateUserDto } from "../definitions/Landing";



export const saveUserInfo = (userId, taskId, tmCodeId) => ({
  type: UserActionTypes.SET_USER_ID,
  payload: { userId, taskId, tmCodeId },
});

export const updateEmail = (email: string) => async (
  dispatch: AppThunkDispatch
): Promise<boolean | null> => {
  const { editor } = store.getState().landingAdminReducer;
  if (editor)
    return false;
  const { debug } = store.getState().landing;
  if (debug)
    return false;
  const { userId, taskId, tmCodeId } = store.getState().user;
  const result = await UserConsentService.updateEmail(userId, email, taskId, tmCodeId);
  dispatch({ type: UserActionTypes.SET_CONSENT, payload: email })
  return result;
};

export const sendConsent = () => async (
  dispatch: AppThunkDispatch
): Promise<boolean | null> => {
  const { editor } = store.getState().landingAdminReducer;
  if (editor)
    return false;

  const { debug } = store.getState().landing;
  if (debug)
    return false;
  const { userId, taskId, tmCodeId } = store.getState().user;
  const result = await UserConsentService.sendConsent(userId, taskId, tmCodeId);
  dispatch({ type: UserActionTypes.SET_CONSENT, payload: true })
  return result;
};

export const unsubscribe = () => async (
  dispatch: AppThunkDispatch
): Promise<boolean | null> => {
  const { editor } = store.getState().landingAdminReducer;
  if (editor)
    return false;
  const { debug } = store.getState().landing;
  if (debug)
    return false;
  const { userId, taskId, tmCodeId } = store.getState().user;
  const result = await UserConsentService.unsubscribe(userId, taskId, tmCodeId);
  dispatch({ type: UserActionTypes.UNSUBSCRIBE, payload: true })
  return result;
};




export const fetchUserName = () => {
  return async (dispatch) => {
    dispatch({ type: 'FETCH_USER_NAME_REQUEST' });
    const { debug } = store.getState().landing;
    const { editor } = store.getState().landingAdminReducer;
    if (editor || debug){
      dispatch({ type: UserActionTypes.SET_USER_NAME, payload: "{{UserName}}" });
      return;
    }
    try {
      const { userId, taskId } = store.getState().user;
      const name = await UserConsentService.fetchUserName(userId, taskId);

      dispatch({ type: UserActionTypes.SET_USER_NAME, payload: name });
    } catch (error) {
      console.error('Error fetching user name:', error);
      dispatch({ type: 'FETCH_USER_NAME_FAILURE', payload: error });
    }
  };
};

export const fetchUserEmail = () => {
  return async (dispatch) => {
    dispatch({ type: 'FETCH_USER_EMAIL_REQUEST' });

    try {
      const { userId, taskId } = store.getState().user;
      const name = await UserConsentService.fetchUserEmail(userId, taskId);

      dispatch({ type: UserActionTypes.UPDATE_EMAIL, payload: name });
    } catch (error) {
      console.error('Error fetching user name:', error);
      dispatch({ type: UserActionTypes.UPDATE_EMAIL, payload: "placeholder" });
    }
  };
};

export const createUser = (newUser: CreateUserDto) => {
    return async (dispatch) => {
        dispatch({ type: 'CREATE_USER_REQUEST' });

        try {
            const { taskId } = store.getState().user;
            const craetedUser = await UserConsentService.createUser(newUser, taskId);
            
            dispatch({ type: UserActionTypes.SET_USER, payload: craetedUser });
        } catch (error) {
            console.error('Error creating new user:', error);
            dispatch({ type: UserActionTypes.SET_USER, payload: null });
        }
    };
}