

import React, { useEffect, useState } from 'react';
import { AuthService } from '../authentication/user-manager';


function CallbackComponent() {
  
useEffect(() => {
    let service = new AuthService();
    service.userManager.signinRedirectCallback();

}, []);

return (
    <div>
        <h1>logged in</h1>
    </div>
);
}



export default CallbackComponent;
