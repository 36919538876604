

export default function(dialog: any, externalId?: string){
    dialog.add("shortLinkDialog", function (editor: any) {
        return {
          title: "Link Shortener",
          minWidth: 400,
          minHeight: 200,
          contents: [
            {
              id: "tab-basic",
              label: "Basic Settings",
              elements: [
                {
                  type: "text",
                  id: "link-full",
                  label: "Full link",
                  validate: dialog.validate.notEmpty(
                    "Full link field cannot be empty."
                  ),
                  setup: function (element: any) {
                    dialog
                      .getCurrent()
                      .setValueOf(
                        "tab-basic",
                        "link-full",
                        element.getAttribute("link-full")
                      );
                  },
                  commit: function (element: any) {
                    element.setAttribute(
                      "link-full",
                      dialog
                        .getCurrent()
                        .getValueOf("tab-basic", "link-full")
                    );
                  },
                },
                {
                  type: "text",
                  id: "link-short",
                  label: "Short link",
                  setup: function (element: any) {
                    dialog
                      .getCurrent()
                      .setValueOf("tab-basic", "link-short", element.getText());
                  },
                  commit: function (element: any) {
                    element.setText(
                      dialog
                        .getCurrent()
                        .getValueOf("tab-basic", "link-short")
                    );
                  },
                  disabled: true,
                },
              ],
            },
          ],
         /*  onOk: function (ev: any) {
            ev.data.hide = false;
            const dialog = ev.sender.definition.dialog;
  
            const data: AddMessageLinkContract = {
              Href: dialog.getValueOf("tab-basic", "link-full"),
              MessageId: externalId ?? "",
              // Name: dialog.getValueOf("tab-basic", "link-full"),
            };
            AdminService.addMessageLink(data).then((link: Link) => {
              const shortValue = `https://myhouse.dk/link/${link.UrlToken}`;
              dialog.setValueOf("tab-basic", "link-short", shortValue);
  
              const shortLink = dialog.element;
              dialog.commitContent(shortLink);
  
              if (dialog.insertMode) editor.insertElement(shortLink);
              dialog.hide();
            });
          }, */
          onShow: function (ev: any) {
            const selection = editor.getSelection();
            let element = selection.getStartElement();
  
            if (element) element = element.getAscendant("shortlink", true);
  
            const dialog = ev.sender.definition.dialog;
            if (!element || element.getName() !== "shortlink") {
              element = editor.document.createElement("shortlink");
              dialog.insertMode = true;
            } else dialog.insertMode = false;
  
            dialog.element = element;
            if (!dialog.insertMode) dialog.setupContent(dialog.element);
          },
        };
      });

};