import { Reducer } from "redux";

import { AppAction } from "../definitions/Action";
import { LandingActionTypes } from "../constants/landing.actiontypes";
import { LandingPageDto, WidgetModel } from "../definitions/Landing";
import { useSelector } from "react-redux";
import { ApplicationState } from "./store";

export const useLandingState = (): LandingStoreState => useSelector<ApplicationState, LandingStoreState>(state => state.landing);

export interface LandingStoreState {
  loading: boolean;
  error: string | null;
  usersCount: number;
  widgets: WidgetModel[];
  widgetsCount: number;
  landing: LandingPageDto | null;
  debug: boolean;
}

const initialState: LandingStoreState = {
  loading: false,
  error: null,
  usersCount: 0,
  widgets: [],
  widgetsCount: 0,
  landing: null,
  debug: false
};

const landingReducer: Reducer<LandingStoreState, AppAction> = (
  state: LandingStoreState = initialState,
  action: AppAction
): LandingStoreState => {
  switch (action.type) {
    case LandingActionTypes.GET_USERS_COUNT:
    case LandingActionTypes.GET_WIDGETS:
    case LandingActionTypes.GET_LANDING:
      return { ...state, loading: true };
    case LandingActionTypes.GET_USERS_COUNT_SUCCEEDED:
      return {
        ...state,
        loading: false,
        usersCount: action.payload,
      };
    case LandingActionTypes.GET_WIDGETS_SUCCEEDED:
      return {
        ...state,
        widgets: action.payload.Results,
        widgetsCount: action.payload.Count,
        loading: false,
      };
    case LandingActionTypes.GET_WIDGETS_ADDITIONAL_SUCCEEDED:
      return {
        ...state,
        widgets: [...state.widgets, ...action.payload.Results],
        widgetsCount: action.payload.Count,
        loading: false,
      };
    case LandingActionTypes.GET_LANDING_SUCCEEDED:
      return {
        ...state,
        landing: action.payload,
        loading: false,
      };
    case LandingActionTypes.ADD_LEAD_SUCCEEDED:
      return { ...state, loading: false };
    case LandingActionTypes.DEBUG:{
      return { ...state, debug: action.payload };
    }
    default:
      return state;
  }
};

export default landingReducer;
