import { GrapesEditor, GrapesJs, GrapesTypes } from "../defenitions";
import ParserHtmlCaseSensitive from "./ParserHtmlCaseSensitive";
import { loadComponents } from  "./components"
import { LandingType } from "../../../definitions/Landing";


export {
  ButtonConsent,
  EmailConsent,
  UserName,
  Unsubscribe,
  NoActionConsent,
  CampaignUnsubscribe,
  SignUp,
  NoActionUnsubscribe
} from "./components"
export const initComponents = (
  grapesjs: GrapesJs,
  type: LandingType,
  theme?: string
): void => {
  grapesjs.plugins.add(GrapesTypes.ReactComponentsPlugin, (editor) => {
    const model = editor.getModel();
    const config = model.get("Config");
    config.textTags = ["br", "b", "i", "u", "a", "ul", "ol"];
    const parser = model.get("Parser");
    parser.parserHtml = ParserHtmlCaseSensitive(config);
    parser.parseHtml = (str: string) => {
      const pHtml = model.get("Parser").parserHtml;
      pHtml.compTypes = model.get("DomComponents").componentTypes;
      const res = pHtml.parse(str, model.get("Parser").parserCss);
      return res;
    };

    editor.Commands.add(GrapesTypes.LoadHtmlTemplate, (e: GrapesEditor) => {
      const parserHtml = e.getModel().get("Parser").parserHtml;
      return parserHtml.unquoteJsxExpresionsInAttributes(e.getHtml());
    });

    
    editor.on("run:grapesjs-load-data-complete", () => {
      updateStyles()
    });

    editor.on('update', () => {
      updateStyles()
    })

    editor.on("storage:start:store", (objectToStore) => {
      objectToStore.html = editor.runCommand(GrapesTypes.LoadHtmlTemplate);
    });

    loadComponents(
      editor,
      type,
      {
        categoryLabel: "Custom",
      },
      theme
    );
  });
};

const updateStyles = () => {
  let styles = "";
  const iframe = document.querySelector(".gjs-frame") as HTMLIFrameElement;

  if (!iframe?.contentDocument) return;

  for (const sheet of document.styleSheets) {
    const link = document.createElement("link");
    if (sheet.href) {
      link.href = sheet.href;
      link.rel = "stylesheet";
      link.setAttribute("mat-style-link", "true");
      iframe.contentDocument.head.appendChild(link);
    } else {
      for (const { cssText } of sheet.cssRules) {
        styles += cssText + "\n";
      }
    }
  }

  const style = document.createElement("style");
  style.textContent = styles;
  style.setAttribute("mat-styles", "true");

  const oldStyles = iframe.contentDocument.head.querySelector(
    "style[mat-styles=true]"
  );

  const oldLinks = iframe.contentDocument.head.querySelector(
    "style[mat-style-link=true]"
  );

  const headStyles = iframe.contentDocument.body.firstChild;
  if (headStyles?.textContent) {
    //clear unnecessary styles from iframe style tag
    headStyles.textContent = headStyles.textContent.replace(/body \{(.*?)\}/gm, '');
  }
  if (oldStyles) iframe.contentDocument.head.removeChild(oldStyles);
  if (oldLinks) iframe.contentDocument.head.removeChild(oldLinks);
  iframe.contentDocument.head.appendChild(style);
};

export default initComponents;
