import { User, UserManager, Log, WebStorageStateStore } from 'oidc-client';
import config from '../config/config.json';

export const settings = {
  authority: config.Authority, // Replace with the URL of your Identity Server
  client_id: config.client_id, // Replace with the client ID of your application
  redirect_uri: window.location.origin + '/callback',
  response_type: 'code',
  scope: config.scope, // Add additional scopes as required
  post_logout_redirect_uri: window.location.origin + '/',
  userStore: new WebStorageStateStore({ store: localStorage})
};



export class AuthService {
  public userManager: UserManager;
  constructor() {
   
    this.userManager = new UserManager(settings);

    Log.logger = console;
    Log.level = Log.INFO;
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser();
  }

  public login(): Promise<void> {
    return this.userManager.signinRedirect();
  }

  public renewToken(): Promise<User> {
    return this.userManager.signinSilent();
  }

  public logout(): Promise<void> {
    return this.userManager.signoutRedirect();
  }
}



