import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  CircularProgress,
  Typography,
  ThemeProvider,
  CssBaseline,
  useTheme,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useParams, useLocation} from "react-router";
import { AppThunkDispatch } from "../../definitions/Action";
import { getLandingPage } from "../../actions";
import { LandingPageDto } from "../../definitions/Landing";
import { ConnectedProps, connect } from "react-redux";
import { useAlertContext } from "../MyhouseAlert";
import GrapesJsxRenderer from "../../components/Grapes/GrapesJsxRenderer";
import { ErrorBoundary } from "../../components/ErrorBoundary";
import { useTranslation } from "react-i18next";
import { saveUserInfo } from "src/actions/userActions";

const CustomLandingPage = (
    props: CustomLandingPageConnectedProps
): JSX.Element => {
    const { name, customerId } = useParams<{ name: string, customerId: string }>();
    let [searchParams, setSearchParams] = useSearchParams();
  const preview = searchParams.get("debug") ?? "";

  
  const userId  = searchParams.get("userId");
  const taskId = searchParams.get("taskId");
  const tmCodeId = searchParams.get("tmCodeId");


  const [landing, setLanding] = useState<LandingPageDto | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { showAlert, closeAlert, alertState } = useAlertContext();

  const location = useLocation();
  const { t } = useTranslation("translation");
 const theme = useTheme();
  /* useEffect(() => {
    if (landing && landing.Theme === "theme2") {
      setCurrentTheme(theme2);
      return;
    }

 //   setCurrentTheme(theme);
  }, [landing]); */

  useEffect(() => {
    if (!landing) {
      setIsLoading(true);
      props
        .getLandingPage(name ?? "",customerId ?? "", preview.toLowerCase() == 'true', taskId ?? "", userId ?? "", tmCodeId ?? "")
        .then(setLanding)
        .finally(() => setIsLoading(false));
    }
  }, [landing]);

  const handleError = (e: Error) => {
    if (!landing?.IsPublished) {
      console.error(e);
      //showAlert({
      //  severity: "error",
      //  text: `${e.name}: ${e.message}`,
      //});
    }
  };
  useEffect(() => {
   // if (userId) {
      // Save the userId and taskId to the Redux store
      props.saveUserInfo(userId ?? "", taskId ?? "", "");
    //}
  }, [userId, taskId, tmCodeId]);
  
  useEffect(() => {
    if (landing) {
      const style = document.createElement("style");
      style.setAttribute("data-meta", "landing-" + landing.Id);
      
      style.innerHTML = landing.Css;
      document.head.appendChild(style);
      document.title = landing.Title;
      if (!landing.IsPublished) {
        //showAlert({
        //  severity: "info",
        //  text: "This landing page is not published",
        //  duration: undefined,
        //  position: {
        //    horizontal: "center",
        //    vertical: "top",
        //  },
        //});
      }
      return () => {
        document.head.removeChild(style);
        if (!landing.IsPublished && alertState.open) {
          closeAlert();
        }
      };
    }
  }, [landing]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          style={{ minHeight: "100vh" }}
        >
       
          <ErrorBoundary>
            {isLoading && (
              <Grid container justifyContent="center" item flex={1}>
                <CircularProgress />
              </Grid>
            )}
           {!isLoading && (
              <Grid container justifyContent="center" item flex={1}>
                {landing?.Id ? (
                  <GrapesJsxRenderer jsx={landing.Html} onError={handleError} />
                ) : (
                  <Typography variant="h2">
                    {t("Pages.LandingPage.LandingPageNotFound")}
                  </Typography>
                )}
              </Grid>
            )}
          </ErrorBoundary>
        
        </Grid>
    </ThemeProvider>
  );
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  saveUserInfo: (userId: string, taskId: string, tmCodeId: string) => dispatch(saveUserInfo(userId, taskId, tmCodeId)), // Define the new action
  getLandingPage: (uniqueName: string, customerId: string, debug: boolean, taskId: string, userId: string, tmCodeId: string) => dispatch(getLandingPage(uniqueName, customerId, debug, taskId, userId, tmCodeId)),
});

const connector = connect(null, mapDispatchToProps);

type CustomLandingPageConnectedProps = ConnectedProps<typeof connector>;

export default connector(CustomLandingPage);
