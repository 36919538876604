import ApiService from "./api.service"
import {  LandingMetaModel } from "../definitions/Landing";
import { headers } from "./http.service"


const AdminLandingService = {
  getLanding: async (landingId: string): Promise<LandingMetaModel | null> => {
    const url = `/api/Landing/landing/${landingId}`;
    const result = await ApiService.get<LandingMetaModel | null>(url);
    return result;
  },
  createLanding: async (title: string, uniqueName: string, templateId?: string): Promise<LandingMetaModel> => {
    const url = `/api/admin/Landing/landing`;
    const result = await ApiService.put<LandingMetaModel>(url, {
      Title: title,
      UniqueName: uniqueName,
      TemplateId: templateId
    });
    return result;
  },
  updateLanding: async (landing: LandingMetaModel): Promise<LandingMetaModel> => {
    const url = `/api/admin/Landing/landing`;
    const result = await ApiService.post<LandingMetaModel>(url, landing);
    return result;
  },
  updateFacebookPicture: async (landingId: string, picture: File): Promise<string> => {
    const formData = new FormData();
    const blob = new Blob([picture], { type: picture.type });
    formData.append("File", blob);

    const headersNoContentType = headers();
    delete headersNoContentType["Content-Type"];

    const response = await fetch(`/api/admin/landing/${landingId}/uploadfacebookimage`, {
      method: "POST",
      body: formData,
      headers: headersNoContentType,
    });
    const result = await response.text();
    if (response.status === 200) return result;

    throw result;
  },
  deleteLanding: async (landingId: string): Promise<boolean> => {
    const url = `/api/admin/Landing/landing/${landingId}`;
    const result = await ApiService.delete<boolean>(url);
    return result;
  },
  cloneLanding: async (landingId: string): Promise<LandingMetaModel> => {
    const url = `/api/admin/Landing/landing/${landingId}/clone`;
    const result = await ApiService.put<LandingMetaModel>(url, landingId);
    return result;
  }
};

export default AdminLandingService;
