import { LandingType } from "../../../../definitions/Landing";
import { GrapesEditor, LoadComponentOptions } from "../../defenitions";
import loadEmailConsent  from "./EmailConsent";
import loadUserName from "./UserName";
import loadButtonConsent  from "./ButtonConsent";
import loadUnsubscribe  from "./Unsubscribe";
import loadNoActionConsent from './NoActionConsent';
import loadCampaignUnsubscribe from './CampaignUnsubscribe';
import loadSignUp from './SignUpForm';
import loadNoActionUnsubscribe from './NoActionUnsubscribe';
export { EmailConsent }  from "./EmailConsent";
export { UserName } from "./UserName";
export { ButtonConsent }  from "./ButtonConsent";
export { Unsubscribe } from "./Unsubscribe";
export { NoActionConsent } from './NoActionConsent';
export { CampaignUnsubscribe } from './CampaignUnsubscribe';
export { SignUp } from './SignUpForm';
export { NoActionUnsubscribe } from './NoActionUnsubscribe';
// Checks for react component name inside of component wrapper
export const isReactComponent = (
  element: HTMLElement,
  name: string
): boolean => {
  for (let index = 0; index < element.childNodes.length; index++) {
    if ((element.childNodes[index] as any).tagName === name) return true;
  }
  return false;
};
export const loadComponents = function (
    editor: GrapesEditor,
    type: LandingType,
    options: LoadComponentOptions,
    theme?: string
  ): void {
    loadButtonConsent(editor, options);
    loadEmailConsent(editor, options );
    loadUserName(editor, options);
    loadUnsubscribe(editor, options);
    loadNoActionConsent(editor, options);
    loadCampaignUnsubscribe(editor, options);
    loadSignUp(editor, options);
    loadNoActionUnsubscribe(editor, options);
  };