import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { isReactComponent } from ".";
import {
  GrapesEditor,
  GrapesTypes,
  LoadComponentOptions,
} from "../../defenitions";
import SendConsent from "../../../Base/SendConsent";
import { Provider } from "react-redux";
import store from "../../../../store/store";

type EmailConsentProps = {
  text: string;
  message: string;
  setEmail: boolean
};

export const EmailConsent = (props: EmailConsentProps): JSX.Element => {
  const { text, message, setEmail } = props;


  return (

      <SendConsent text={text} message={message} setEmail={setEmail} />

  );
};

export default function (
  editor: GrapesEditor,
  options: LoadComponentOptions
): void {
  const domComponents = editor.DomComponents;
  const blockManager = editor.BlockManager;
  const defaultType = domComponents.getType("default");
  const { model, view } = defaultType;
  const compName = "EmailConsent";

  blockManager.add(GrapesTypes.EmailConsent, {
    label: "Email Input",
    category: options.categoryLabel,
    attributes: { class: "fa fa-sign-in" },
    textable: 1,
    content: `<span data-gjs-type="${GrapesTypes.EmailConsent}"></span>`,
  });

  const traits = [
    {
      label: "Button text",
      name: "text",
      changeProp: 1,
    },
    {
      label: "Message",
      name: "message",
      changeProp: 1,
    },
    {
      label: "Set email",
      name: "setEmail",
      changeProp: 1,
    }

  ];


  domComponents.addType(GrapesTypes.EmailConsent, {
    isComponent(el: HTMLElement) {
      if (
        (el.getAttribute &&
          el.getAttribute("data-gjs-type") ===
          GrapesTypes.EmailConsent) ||
        (el.attributes &&
          (el.attributes as any)["data-gjs-type"] ===
          GrapesTypes.EmailConsent) ||
        isReactComponent(el, compName)
      ) {
        return {
          type: GrapesTypes.EmailConsent,
        };
      }
    },
    model: {
      defaults: {
        ...model.prototype.defaults,
        droppable: false,
        draggable: true,
        text: "Thank you",
        setEmail: true,
        traits: [...model.prototype.defaults.traits, ...traits],
      },
    },
    view: view.extend({
      init() {
        traits.forEach((trait) => {
          this.listenTo(this.model, `change:${trait.name}`, this.handleChanges);
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      handleChanges(e: any, value: any) {
        ReactDOM.unmountComponentAtNode(this.el);
        this.render();
      },
      onRender() {
        const { el } = this;
        const comps = this.model.get("components");
        const { text, message, setEmail } = this.model.attributes;
        comps.reset();

        const compString = `<${compName} text='${text}' message='${message}' setEmail='${setEmail}'></${compName}>`;
        comps.add(compString);

        ReactDOM.render(<Provider store={store}>
          <SendConsent text={text} message={message} setEmail={setEmail}/>
        </Provider>, el);
      },
    }),
  });
}
