
 import {
  ButtonConsent,
  EmailConsent,
  UserName,
  Unsubscribe,
  NoActionConsent,
  CampaignUnsubscribe,
  SignUp,
  NoActionUnsubscribe
} from "./ReactComponentsPlugin";

export const components = {
  ButtonConsent,
  EmailConsent,
  UserName,
  Unsubscribe,
  NoActionConsent,
  CampaignUnsubscribe,
  SignUp,
  NoActionUnsubscribe
};
 