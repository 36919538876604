import { User } from "oidc-client";
import { settings } from '../authentication/user-manager';
export const handleResponse = <T>(response: Response): Promise<T> =>
  new Promise((resolve, reject) => {
    if (response.ok) {
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        response.json().then(resolve);
      } else {
      //  resolve();
      }
    } else {
      response.text().then(reject);
    }
  });

export const handleError = (error: any) => {
  return Promise.reject(error && error.message);
};

export const headers = () => {
  const headerOptions: any = {
      'Content-Type': 'application/json'
  };
  
  const user = localStorage.getItem(`oidc.user:${settings.authority}:${settings.client_id}`);
   if(user !== null){
    const parsedUser = JSON.parse(user) as User;
    if(parsedUser){
        headerOptions['Authorization'] = `Bearer ${parsedUser.access_token}`;
    }
  } 

  return headerOptions;
};