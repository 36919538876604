import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CreateUserDto } from "../../definitions/Landing";
import { createUser, sendConsent } from "../../actions/userActions";
import { divide } from "lodash";
import {
  Box,
  Button,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";

function SignUpFormComponent({ consent, inputProps, redirectUrl, createUser }) {
  const initialUser: CreateUserDto = {
    email: "",
    fornavn: "",
    efternavn: "",
    titel: "",
    stedadresse1: "",
    stedBy: "",
    stednavn1: "",
    stedtelefon: "",
    stedPostnr: "",
    userdefinedString1: "",
  };
  const [newUser, setNewUserValue] = useState<CreateUserDto>(initialUser);
  const [errorText, setErrorText] = useState<string>("");
  const handleSubmit = () => {
    if(inputFields?.some((prop) => prop.required && newUser[prop.name] == "")){
      setErrorText("Please fill out all required fields");
      return;
    }
    createUser(newUser);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewUserValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  useEffect(() => {
    if (consent) {
      window.location.href = redirectUrl;
    }
  }, [consent]);
  let inputFields: any[] | null = null;
  try {
    inputFields = JSON.parse(inputProps);
  } catch (e) {
    console.log(e);
  }
  return (
    <Box>
      {inputFields == null ? (
        <Typography color="error">JsonPropsNotValid</Typography>
      ) : (
        <Grid
          p={1}
          component="form"
          container
          direction="column"
          onSubmit={handleSubmit}
          spacing={2}
        >
          {inputFields.map((prop, index) => (
            <Grid item>
              <InputLabel shrink>{prop.label}</InputLabel>
              <TextField
                sx={{
                  width: "100%",
                  "& .MuiInputBase-root.MuiOutlinedInput-root": {
                    borderRadius: "4px",
                  },
                  "& .MuiFormHelperText-root": {
                    position: "absolute",
                    right: 0,
                    bottom: -18,
                    zIndex: 10,
                  },
                }}
                variant="outlined"
                size="small"
                type={prop.type}
                placeholder={prop.placeholder}
                name={prop.name}
                onChange={handleChange}
                error={prop.required && newUser[prop.name] == "" ? true : false}
                helperText={prop.required && newUser[prop.name] == "" ? prop.label + " is required": ""}
              />
            </Grid>
          ))}
          <Grid mt={2} item container justifyContent={"flex-end"}>
            <Button
              type="button"
              variant="outlined"
              color="success"
              onClick={handleSubmit}
            >
              Submit
            </Button>
            <Typography color="error">{errorText}</Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

const mapStateToProps = (state) => {
  return {
    consent: state.user.consent,
    redirectUrl: state.landing.landing.RedirectUrl,
  };
};

export default connect(mapStateToProps, { createUser })(SignUpFormComponent);
