import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { sendConsent } from '../../actions/userActions';


function NoActionConsentComponent({ consent,redirectUrl, sendConsent }) {
 

  useEffect(() => {
    sendConsent();
  }, []);
  useEffect(() => {
    // Check if consent changes to true
    if (consent) {
      window.location.href = redirectUrl
    }
  }, [consent]);
  return (
    <div>
      
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    consent: state.user.consent,
    redirectUrl: state.landing.landing.RedirectUrl
  };
};

export default connect(mapStateToProps, { sendConsent })(NoActionConsentComponent);