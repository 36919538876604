import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { unsubscribe } from '../../actions/userActions';


function NoActionUnsubscribeComponent({ unsubscribed,redirectUrl, unsubscribe }) {
 

  useEffect(() => {
    unsubscribe();
  }, []);
  useEffect(() => {
    // Check if consent changes to true
    if (unsubscribed) {
      window.location.href = redirectUrl
    }
  }, [unsubscribed]);
  return (
    <div>
      
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    unsubscribed: state.user.unsubscribed,
    redirectUrl: state.landing.landing.RedirectUrl
  };
};

export default connect(mapStateToProps, { unsubscribe })(NoActionUnsubscribeComponent);