
import { CreateUserDto } from "../definitions/Landing";
import ApiService from "./api.service";



const UserConsentService = {
    sendConsent: async (userId: string, tmTaskId: string,tmCodeId: string): Promise<boolean> => {
        const url = `/api/user/sendConsent`;
        const result = await ApiService.post<boolean>(url, { userId : userId, tmTaskId : tmTaskId, tmCodeId : tmCodeId});
        return result;
      },
    
      updateEmail: async (userId: string, email: string, tmTaskId: string, tmCodeId: string): Promise<boolean> => {
        const url = `/api/user/sendEmailConsent`;
        const result = await ApiService.post<boolean>(url, { userId : userId, email : email,tmTaskId : tmTaskId, tmCodeId : tmCodeId  });
        return result;
      },

      unsubscribe: async (userId:string, tmTaskId: string, tmCodeId: string) => {
        const url = `/api/user/unsubscribe`;
        const result = await ApiService.post<boolean>(url, { userId : userId, tmTaskId : tmTaskId, tmCodeId : tmCodeId  });
        return result;
      },
      fetchUserName: async (userId: string, tmTaskId: string) => {
        const url = `/api/user/getUserName/${tmTaskId}/${userId}`;
        const result = await ApiService.get<any>(url);
        return result.name;
      },
      fetchUserEmail: async (userId: string, tmTaskId: string) => {
        const url = `/api/user/getUserEmail/${tmTaskId}/${userId}`;
        const result = await ApiService.get<any>(url);
        return result.name;
      },
      createUser: async (user: CreateUserDto, taskId: string) => {
          const url = `/api/user/createUser`;
          const result = await ApiService.post<any>(url, {...user, primaryTMOpgave: taskId});
          return result;
      }

}


export default UserConsentService;