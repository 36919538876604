import React, { useEffect, useState } from "react";
import { Navigate } from "react-router";
import { AuthService } from "./authentication/user-manager";
import { User } from "oidc-client";
import {useLocation } from 'react-router-dom'
const ProtectedRoute = ({ children }) => {
  const [user, setUser] = useState({});
  const history = useLocation();
  useEffect(() => {
    const fetchUser = async () => {
      let service = new AuthService();
      const currentUser = await service.getUser() as User;
      console.log(currentUser);
      if(currentUser && currentUser.expired == false){
        setUser(currentUser ?? {});
      }
      else{
        service.login();
      }
     
    };

    fetchUser();
  }, []);

 if(!user)
    return <Navigate to="/landing" replace />;
  

  return children;
};

export default ProtectedRoute;