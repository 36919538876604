import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { sendConsent } from '../../actions/userActions';


function ButtonConsentComponent({ buttonText, message, consent,redirectUrl, sendConsent }) {
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const handleButtonClick = () => {
    // Validate email format
  
    sendConsent();
    
  };
  useEffect(() => {
    // Check if consent changes to true
    if (consent) {
      window.location.href = redirectUrl
    }
  }, [consent]);
  return (
    <div>
      {!consent ? (
        <button onClick={handleButtonClick}>{buttonText}</button>
      ) : (
        <p>{message}</p>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    consent: state.user.consent,
    redirectUrl: state.landing.landing.RedirectUrl
  };
};

export default connect(mapStateToProps, { sendConsent })(ButtonConsentComponent);