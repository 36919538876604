import {  LandingMetaModel } from "../definitions/Landing"


import { AppAction, AppThunkDispatch } from "../definitions/Action";
import { AdminLandingActionTypes } from "../constants/landingActions";
import  AdminLandingService from "../services/landing-page.service";
import { operationFailedActionGeneral } from "./";
import { AppActionThunk } from "../definitions/Action";
import LandingService from "../services/landing.service";

const operationFailedAction = (payload: unknown): AppAction => {
  return operationFailedActionGeneral(
    payload,
    AdminLandingActionTypes.LANDING_OPERATION_FAILED
  );
};


export const clearTemplates = (): AppAction => ({ type: AdminLandingActionTypes.CLEAR_TEMPLATES });


export const getLanding = (landingId: string) => async (
  dispatch: AppThunkDispatch
): Promise<LandingMetaModel | null> => {
  try {
    dispatch({ type: AdminLandingActionTypes.GET_LANDING });
    const result = await AdminLandingService.getLanding(landingId);
    dispatch({ type: AdminLandingActionTypes.GET_LANDING_SUCCEEDED, payload: result });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    return null;
  }
};

export const createLanding = (title: string, uniqueName: string, templateId?: string) => async (
  dispatch: AppThunkDispatch
): Promise<LandingMetaModel | null> => {
  try {
    dispatch({ type: AdminLandingActionTypes.ADD_LANDING });
    const result = await AdminLandingService.createLanding(title, uniqueName, templateId);
    dispatch({ type: AdminLandingActionTypes.ADD_LANDING_SUCCEEDED, payload: result });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    return null;
  }
};

export const updateLanding = (landing: LandingMetaModel) => async (
  dispatch: AppThunkDispatch
): Promise<LandingMetaModel | null> => {
  try {
    dispatch({ type: AdminLandingActionTypes.UPDATE_LANDING });
    const result = await AdminLandingService.updateLanding(landing);
    dispatch({ type: AdminLandingActionTypes.UPDATE_LANDING_SUCCEEDED, payload: result });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
};

export const updateFacebookPicture = (landingId: string, picture: File) => async (
  dispatch: AppThunkDispatch
): Promise<string | null | undefined> => {
  try {
    dispatch({ type: AdminLandingActionTypes.UPDATE_FACEBOOK_PICTURE });
    const result = await AdminLandingService.updateFacebookPicture(landingId, picture);
    dispatch({
      type: AdminLandingActionTypes.UPDATE_FACEBOOK_PICTURE_SUCCEEDED,
      payload: result,
    });

    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
};

export const deleteLanding = (landingId: string) => async (dispatch: AppThunkDispatch): Promise<void> => {
  try {
    dispatch({ type: AdminLandingActionTypes.REMOVE_LANDING });
    if (await AdminLandingService.deleteLanding(landingId)) {
      dispatch({ type: AdminLandingActionTypes.REMOVE_LANDING_SUCCEEDED, payload: landingId });
    } else {
      dispatch(operationFailedAction("landing was not deleted"));
    }
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const cloneLanding = (landingId: string) => async (dispatch: AppThunkDispatch): Promise<void> => {
  try {
    dispatch({ type: AdminLandingActionTypes.CLONE_LANDING });
    const result = await AdminLandingService.cloneLanding(landingId);
    dispatch({ type: AdminLandingActionTypes.CLONE_LANDING_SUCCEEDED, payload: result });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
}

export const clearLandings = (): { type: string } => ({ type: AdminLandingActionTypes.CLEAR_LANDINGS });
//export const setUrl = (url: string): { type: string, payload: string } => ({ type: AdminLandingActionTypes.SET_API_URL, payload : url  });
 export const setUrl = (url: string) => async (
  dispatch: AppThunkDispatch
): Promise<boolean> => {
  try {
  
    dispatch({ type: AdminLandingActionTypes.SET_API_URL, payload: url });
    return true;
  } catch (error) {
    console.log(error);
    dispatch(operationFailedAction(error));
  return false;
  }
}; 

export const getEditor =
  (editorId: string): AppActionThunk<Promise<any>> =>
  async (dispatch) => {
    try {
      dispatch({ type: AdminLandingActionTypes.GET_EDITOR });
      const result = await LandingService.getEditor(editorId);
      dispatch({ type: AdminLandingActionTypes.GET_EDITOR_SUCCEEDED });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };
