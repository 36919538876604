import * as React from 'react';

import store from "./store/store";



import './custom.css'
import CustomLandingPage from './components/LandingPage/CustomLandingPage';
import { Provider } from 'react-redux';
import { Route, Routes } from 'react-router';
import EditLandingPage from './components/LandinPageEditor/EditLandingPage';
import ProtectedRoute from './ProtectedRoute';
import CallbackComponent from './components/CallBack';
import NotFound from './components/NotFound/NotFound';

export default () => (
    <Provider store={store}>

        <Routes>
            <Route path='landing/:customerId/:name' element={<CustomLandingPage />} />
            <Route path='editor/:id' element={
                <ProtectedRoute >
                    <EditLandingPage />
                </ProtectedRoute>
            } />
            <Route path='callback' element={
               <CallbackComponent></CallbackComponent>
            } />
            <Route path="notfound" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
        </Routes>

    </Provider>
);
