import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { isReactComponent } from ".";
import {
    GrapesEditor,
    GrapesTypes,
    LoadComponentOptions,
} from "../../defenitions";
import { Provider } from "react-redux";
import store from "../../../../store/store";
import SignUpForm from "../../../Base/SignUpForm";



type SignUpProps = {
    inputProps: string;
};

export const SignUp = (props: SignUpProps): JSX.Element => {
    const { inputProps } = props;
    
    return (
        <SignUpForm inputProps={inputProps}></SignUpForm>
    );
};

export default function (
    editor: GrapesEditor,
    options: LoadComponentOptions
): void {
    const domComponents = editor.DomComponents;
    const blockManager = editor.BlockManager;
    const defaultType = domComponents.getType("default");
    const { model, view } = defaultType;
    const compName = "SignUp";

    blockManager.add(GrapesTypes.SignUp, {
        label: "Sign up form",
        category: options.categoryLabel,
        attributes: { class: "fa fa-sign-out" },
        textable: 1,
        content: `<span data-gjs-type="${GrapesTypes.SignUp}"></span>`,
    });




    const traits = [
        {
            label: "Input fields",
            name: "inputProps",
            changeProp: 1,

        },

    ];


    domComponents.addType(GrapesTypes.SignUp, {
        isComponent(el: HTMLElement) {
            if (
                (el.getAttribute &&
                    el.getAttribute("data-gjs-type") ===
                    GrapesTypes.SignUp) ||
                (el.attributes &&
                    (el.attributes as any)["data-gjs-type"] ===
                    GrapesTypes.SignUp) ||
                isReactComponent(el, compName)
            ) {
                return {
                    type: GrapesTypes.SignUp,
                };
            }
        },
        model: {
            defaults: {
                ...model.prototype.defaults,
                droppable: false,
                draggable: true,
                buttonText: "SignUp",
                message: "Thank you!",
                traits: [...model.prototype.defaults.traits, ...traits],
            },
        },
        view: view.extend({
            init() {
                traits.forEach((trait) => {
                    this.listenTo(this.model, `change:${trait.name}`, this.handleChanges);
                });
            },
            handleChanges(e: any, value: any) {
                ReactDOM.unmountComponentAtNode(this.el);
                this.render();
            },
            onRender() {
                const { inputProps } = this.model.attributes;
                

                const { el } = this;
                const comps = this.model.get("components");
                comps.reset();

                const compString = `<${compName} inputProps='${inputProps}'></${compName}>`;
                comps.add(compString);

                ReactDOM.render(<Provider store={store}>
                    <SignUpForm inputProps={inputProps} ></SignUpForm>
                </Provider>, el);
            },
        }),
    });
}
