import { AppAction } from "../definitions/Action";
import { ApiError } from "../services/api.service";


export * from "./landing.actions";


export const operationFailedActionGeneral = (
  payload: unknown,
  type: string
): AppAction => {
  let message = String(payload);
  if (payload instanceof Error) message = (payload as Error).message;

  try {
    message = (payload as ApiError).statusText;
  } catch {
    //
  }

  return {
    type: type,
    payload: message,
  };
};
