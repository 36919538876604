
import ApiService from "./api.service";



const CampaignService = {
    unsubscribe: async (userId:string) => {
        const url = `/api/campaign/unsubscribe`;
        const result = await ApiService.post<boolean>(url, { userId : userId });
        return result;
      },
}


export default CampaignService;