import { createStore, applyMiddleware, Middleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from ".";

import { LandingStoreState } from "./landing.reducer";
import { UserStoreState } from "./user";
import { LandingAdminStoreState } from "./admin-landing.reducer";

export interface ApplicationState {
  adminLanding: LandingAdminStoreState;
  landing: LandingStoreState;
  user: UserStoreState
}

const initialState: any = {
  
};

// eslint-disable-next-line @typescript-eslint/ban-types
const middlewares: Middleware<{}, ApplicationState>[] = [
  thunk,
  
  // co2TempPlanChecker,
];

//adding logger for the dev build
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
//  middlewares.push(actionLogger);
}

const store = createStore(
  rootReducer,
  initialState,
  // (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__(),
  composeWithDevTools(applyMiddleware(...middlewares))
);

export default store;
