import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchUserName } from '../../actions/userActions';

const UserComponent = ({ userName, fetchUserName }) => {
  useEffect(() => {
    fetchUserName();
  }, [fetchUserName]);

  return (
    <div>
    
       <p>{userName}</p> 
    
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userName: state.user.name,
  };
};

const mapDispatchToProps = {
  fetchUserName,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserComponent);
