import { Reducer } from "redux";

import { AppAction } from "../definitions/Action";
import { UserActionTypes } from "../definitions/userActions";



export interface UserStoreState {
    userId: string;
    email: string;
    name: string,
    taskId: string,
    tmCodeId: string,
    consent: boolean,
    unsubscribed: boolean
}

const initialState: UserStoreState = {
    userId: "",
    email: "",
    name: "",
    taskId: "",
    tmCodeId: "",
    consent: false,
    unsubscribed : false
}

const userReducer: Reducer<UserStoreState, AppAction> = (
    state: UserStoreState = initialState,
    action: AppAction
): UserStoreState => {
    switch (action.type) {
        case UserActionTypes.SET_USER_ID:
            return {
                ...state,
                userId: action.payload.userId,
                taskId: action.payload.taskId,
                tmCodeId: action.payload.tmCodeId

            }
        case UserActionTypes.UPDATE_EMAIL:
            return {
                ...state,
                email: action.payload,
            }
        case UserActionTypes.SET_USER_NAME:
            return {
                ...state,
                name: action.payload
            }

        case UserActionTypes.SET_CONSENT:
            return {
                ...state,
                consent: action.payload
            }

        case UserActionTypes.UNSUBSCRIBE:
            return {
                ...state,
                unsubscribed: action.payload
            }

        case UserActionTypes.SET_USER:
            return {
                ...state,
                email: action.payload.email,
                userId: action.payload.userId,
                name: action.payload.name,
                consent: true,
            }
        default:
            return state;
    }
};

export default userReducer;