import { combineReducers } from "redux";


import landing from "./landing.reducer";
import user from './user';
import landingAdminReducer from './admin-landing.reducer';
const combinedReducer = combineReducers({
  landingAdminReducer,
  landing,
  user
});

//TODO: make initial state if needed
const rootReducer = (state: any, action: any) => {
 /*  if (action.type === UserConstants.LOGOUT) {
    state = { landing: state.landing };
  } */
  return combinedReducer(state, action);
};

export default rootReducer;
