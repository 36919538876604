import { Reducer } from "redux";

import { AppAction } from "../definitions/Action";
import { AdminLandingActionTypes } from "../constants/landingActions";
import { WidgetModel, LandingMetaModel, TemplateModel } from "../definitions/Landing";


export interface LandingAdminStoreState {
  loading: boolean;
  error: string | null;
  searchString: string;
  widgets: WidgetModel[];
  widgetsCount: number;
  landings: LandingMetaModel[];
  landingsCount: number;
  templates: TemplateModel[];
  templatesCount: number | null;
  apiUrl: string;
  editor: boolean;
}

const initialState: LandingAdminStoreState = {
  loading: false,
  error: null,
  searchString: "",
  widgets: [],
  widgetsCount: 0,
  landings: [],
  landingsCount: 0,
  templates: [],
  templatesCount: null,
  apiUrl: "",
  editor: false,
};

const landingAdminReducer: Reducer<LandingAdminStoreState, AppAction> = (
  state: LandingAdminStoreState = initialState,
  action: AppAction
): LandingAdminStoreState => {
  switch (action.type) {
    case AdminLandingActionTypes.GET_WIDGETS:
    case AdminLandingActionTypes.UPDATE_WIDGET:
    case AdminLandingActionTypes.REMOVE_WIDGET:
    case AdminLandingActionTypes.ADD_WIDGET:
    case AdminLandingActionTypes.GET_LANDINGS:
    case AdminLandingActionTypes.GET_LANDING:
    case AdminLandingActionTypes.GET_TEMPLATE:
    case AdminLandingActionTypes.GET_TEMPLATES:
    case AdminLandingActionTypes.UPDATE_TEMPLATE:
    case AdminLandingActionTypes.REMOVE_TEMPLATE:
    case AdminLandingActionTypes.ADD_LANDING:
    case AdminLandingActionTypes.CLONE_LANDING:
      return { ...state, loading: true };
    case AdminLandingActionTypes.GET_WIDGETS_SUCCEEDED:
      return {
        ...state,
        widgets: [...state.widgets, ...action.payload.Results],
        widgetsCount: action.payload.Count,
        loading: false,
      };
    case AdminLandingActionTypes.ADD_WIDGET_SUCCEEDED:
      return {
        ...state,
        widgets: [action.payload, ...state.widgets],
        loading: false,
      };
    case AdminLandingActionTypes.UPDATE_WIDGET_SUCCEEDED:
      return {
        ...state,
        widgets: state.widgets.map((x) => (x.Id === action.payload.Id ? action.payload : x)),
        loading: false,
      };
    case AdminLandingActionTypes.SET_SEARCH_STRING:
      return { ...state, searchString: action.payload };
    case AdminLandingActionTypes.REMOVE_WIDGET_SUCCEEDED:
      return {
        ...state,
        widgets: state.widgets.filter((w) => w.Id !== action.payload),
        widgetsCount: state.widgetsCount - 1,
        loading: false,
      };
    case AdminLandingActionTypes.RESET_WIDGETS:
      return { ...state, widgets: [], widgetsCount: 0 };
    case AdminLandingActionTypes.RESET_ERROR:
      return { ...state, error: null };
    case AdminLandingActionTypes.LANDING_OPERATION_FAILED:
      return { ...state, error: action.payload, loading: false };
    case AdminLandingActionTypes.ADD_LEAD_SUCCEEDED:
      return { ...state, loading: false };
    case AdminLandingActionTypes.GET_LANDINGS_SUCCEEDED:
      return {
        ...state,
        landings: [...state.landings, ...action.payload.Results],
        landingsCount: action.payload.Count,
        loading: false,
      };
    case AdminLandingActionTypes.ADD_LANDING_SUCCEEDED:
    case AdminLandingActionTypes.CLONE_LANDING_SUCCEEDED:
      return {
        ...state,
        landings: [action.payload, ...state.landings],
        landingsCount: state.landingsCount + 1,
        loading: false
      };
    case AdminLandingActionTypes.REMOVE_LANDING_SUCCEEDED:
      return {
        ...state,
        landings: state.landings.filter((l) => l.Id !== action.payload),
        landingsCount: state.landingsCount - 1,
        loading: false,
      };
    case AdminLandingActionTypes.CLEAR_LANDINGS:
      return { ...state, landings: [], landingsCount: 0 };
    case AdminLandingActionTypes.ADD_TEMPLATE_SUCCEEDED:
      return {
        ...state,
        templates: [...state.templates, action.payload],
        loading: false,
      };
    case AdminLandingActionTypes.GET_TEMPLATES_SUCCEEDED:
      return {
        ...state,
        templates: [...state.templates, ...action.payload.Results],
        templatesCount: action.payload.Count,
        loading: false,
      };
    case AdminLandingActionTypes.REMOVE_TEMPLATE_SUCCEEDED:
      return {
        ...state,
        templates: state.templates.filter((t) => t.Id !== action.payload),
        templatesCount: state.landingsCount - 1,
        loading: false,
      };
    case AdminLandingActionTypes.CLEAR_TEMPLATES:
      return {
        ...state,
        templates: [],
        templatesCount: null,
      };
    case AdminLandingActionTypes.UPDATE_LANDING_SUCCEEDED:
      return {
        ...state,
        landings: state.landings.map((l) => (l.Id === action.payload.Id ? action.payload : l)),
        loading: false,
      };
    case AdminLandingActionTypes.UPDATE_TEMPLATE_SUCCEEDED:
      return { ...state, loading: false };
    case AdminLandingActionTypes.SET_API_URL:
      return {
        ...state,
        apiUrl : action.payload
      
    }
    case AdminLandingActionTypes.GET_EDITOR_SUCCEEDED:{
      return {
        ...state,
        editor : true
      }
    }
    default:
      return state;
  }
};

export default landingAdminReducer;
