import React from "react";
import { Alert, AlertTitle, Container } from "@mui/material";

type Props = {
    children: React.ReactNode
};
type State = {
    error: Error | null;
};

export class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { error: null };
    }

    static getDerivedStateFromError(error: Error) {
        return { error: error };
    }

    componentDidCatch(error: Error, errorInfo: any) {
        console.log(error, errorInfo);
    }

    render() {
        if (this.state.error) {
            return <AlertComponent error={this.state.error} />
        }
        return this.props.children;
    }
}

const AlertComponent = ({error}: State) => {
    return (
        <Container maxWidth="md" sx={{mt: 2}}>
            <Alert severity={"error"}>
                <AlertTitle>{error?.name} : {error?.message}</AlertTitle>
                {error?.stack}
            </Alert>
        </Container>
    )
}