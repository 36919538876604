import { Dispatch } from "redux";
import {
  AppAction,
  AppActionThunk,
  AppThunkDispatch,
} from "../definitions/Action";

import { LandingActionTypes } from "../constants/landing.actiontypes";
import LandingService from "./../services/landing.service";

import {
  LandingPageDto,
  LandingType,
} from "../definitions/Landing";

import { operationFailedActionGeneral } from ".";



export const getLandingPage =
  (uniqueName: string, customerId: string, debug: boolean, taskId: string, userId: string, tmCodeId: string) =>
  async (dispatch: AppThunkDispatch): Promise<LandingPageDto> => {
    try {
      dispatch({ type: LandingActionTypes.DEBUG, payload: debug });
      const result = await LandingService.getLandingPage(uniqueName, customerId, debug, taskId, userId, tmCodeId );
      dispatch({
        type: LandingActionTypes.GET_LANDING_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
     // dispatch(operationFailedAction(error));
      window.location.href = "/notfound";
      throw error;
    }
  };


