import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { updateEmail, fetchUserEmail } from '../../actions/userActions';

function SendConsent({ text, message, consent, setEmail, email, updateEmail, fetchUserEmail, redirectUrl }) {
  const [inputValue, setInputValue] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  useEffect(() => {
    if(setEmail)
      fetchUserEmail();
  }, [fetchUserEmail]);
  
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleButtonClick = () => {
    // Validate email format
    const isValid = validateEmail(inputValue);
    setIsValidEmail(isValid);

    if (isValid) {
      // Handle valid email logic here
      console.log('Valid email:', inputValue);
      updateEmail(inputValue);
    } 
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    // Check if consent changes to true
    if (consent) {
      window.location.href = redirectUrl
    }
  }, [consent]);
  
  useEffect(() => {
    // Check if consent changes to true
    if (email) {
      setInputValue(email);
    }
  }, [email]);

  return (
    <div>
      {!consent ? (
        <div>
          <input type="text" onChange={handleInputChange}  />
          <button onClick={handleButtonClick}>{text}</button>
          {!isValidEmail && <p style={{ color: 'red' }}>Invalid email format</p>}
        </div>
      ) : (
        <p>{message}</p>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    email: state.user.email,
    consent: state.user.consent,
    redirectUrl: state.landing.landing.RedirectUrl
  };
};

export default connect(mapStateToProps, { updateEmail, fetchUserEmail })(SendConsent);